import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.modal');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-modal',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/portal.season.modal/view.scss */
/* src/app/portal.season.modal/view.scss: no such file or directory */`],
})
export class PortalSeasonModalComponent implements OnInit {
    @Input() model: any = null;

    constructor(@Inject( Service) public service: Service) {
        if (!this.model) this.model = service.modal;
    }

    public async ngOnInit() {
    }

    public btnColorClass() {
        if (this.model.opts.status == 'warning')
            return 'inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto';
        if (this.model.opts.status == 'success')
            return 'inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto';
        return 'inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto';
    }
}

export default PortalSeasonModalComponent;