import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.video');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-editor-video',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/portal.dizest.editor.video/view.scss */
/* src/app/portal.dizest.editor.video/view.scss: no such file or directory */`],
})
export class PortalDizestEditorVideoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() dizest: any;
    @Input() editor: any = {};

    public loading: boolean = true;

    public async ngOnInit() {
        await this.service.init();
        this.editor.data = "";
        await this.service.render();
    }

    public imageURI() {
        return this.dizest.api.url('drive', 'video/' + this.editor.path);
    }

    public async download() {
        let url = this.dizest.api.url('drive', 'download/' + this.editor.path);
        window.open(url, '_blank');
    }
}

export default PortalDizestEditorVideoComponent;