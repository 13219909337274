import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angpllar/core";
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Dizest } from "src/libs/portal/dizest/dizest";
import { Workspace } from "src/libs/portal/dizest/ui/workspace";

import DefaultEditor from "src/app/portal.dizest.editor.default/portal.dizest.editor.default.component";
import ImageEditor from "src/app/portal.dizest.editor.image/portal.dizest.editor.image.component";
import VideoEditor from "src/app/portal.dizest.editor.video/portal.dizest.editor.video.component";
import WorkflowEditor from "src/app/portal.dizest.editor.workflow/portal.dizest.editor.workflow.component";

import SettingEditor from "src/app/portal.dizest.editor.setting/portal.dizest.editor.setting.component";
import HealthEditor from "src/app/portal.dizest.editor.health/portal.dizest.editor.health.component";
import TerminalEditor from "src/app/portal.dizest.editor.terminal/portal.dizest.editor.terminal.component";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/page.main/view.scss */
.workflow-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-layout {
    display: block;
  }
  .workflow-layout.tab-selected .workflow-nav {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-sidebar-view {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-content {
    height: 100%;
  }
  .workflow-layout .apps-click {
    display: none !important;
  }
}

.workflow-nav {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .workflow-nav {
    width: 100%;
  }
}
.workflow-nav .workflow-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 49%;
  right: 0;
  width: 16px;
  height: 64px;
  background: var(--wc-border);
  font-size: 16px;
  cursor: pointer;
  z-index: 1900;
}
.workflow-nav .workflow-toggle:hover {
  background: var(--wc-blue);
  color: #fff;
}
.workflow-nav .workflow-toggle.toggle-hide {
  position: fixed;
  left: 0;
}
.workflow-nav .view-content {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-nav .view-content {
    width: 100%;
  }
}
.workflow-nav .view-content .view-header {
  padding: 0;
  padding-top: 0;
  background-color: var(--wc-blue);
  color: #fff;
}
.workflow-nav .view-content .view-header .view-title {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
}
.workflow-nav .view-content .view-header .view-title img {
  height: 38px;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.workflow-nav .view-content .view-header .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.workflow-nav .view-content .view-header .clicks .click {
  font-family: "main-eb";
  cursor: pointer;
  padding: 8px;
  display: inline-block;
  flex: 1;
  position: relative;
  background-color: var(--wc-blue);
  color: #fff;
  font-size: 20px;
}
.workflow-nav .view-content .view-header .clicks .click .action-btn {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks .click:hover {
  background-color: var(--wc-blue-hover);
}
.workflow-nav .view-content .view-header .clicks .click.active .action-btn {
  background-color: #fff;
  color: var(--wc-blue);
}
.workflow-nav .view-content .view-header .clicks .click.active .action-border {
  left: -40%;
  top: 100%;
  width: 180%;
  height: 8px;
  position: absolute;
  background-color: var(--wc-blue);
  clip-path: url(#menu);
  transform: scale(1, -1);
  z-index: 1080;
}
.workflow-nav .view-content .view-header .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.workflow-nav .view-content .view-body {
  height: 100%;
  flex: auto;
  border-right: 1px solid var(--wc-border);
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-nav .view-content .view-body {
    border-right: none;
  }
}
@media (max-width: 768px) {
  .workflow-nav .workflow-toggle {
    display: none;
  }
}

.workflow-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
}
.workflow-content .view-content {
  flex: 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .workflow-plugin-viewer {
    display: none;
  }
}

.workflow-sidebar-view {
  height: 100%;
}

.workflow-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 42px;
  border-left: 1px solid var(--wc-border);
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-sidebar {
    display: none;
  }
}
.workflow-sidebar .btn {
  display: block;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.workflow-sidebar .btn,
.workflow-sidebar .btn .hover-area {
  padding: 8px 12px;
}
.workflow-sidebar .btn .hover-area {
  display: none;
  height: 36px;
  right: 0;
  margin-top: -28px;
  min-width: 80px;
  background-color: var(--wc-blue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.workflow-sidebar .btn .hover-area .sidemenu-icon {
  width: 42px;
  text-align: center;
}
.workflow-sidebar .btn:hover .hover-area {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
  right: 0;
}
.workflow-sidebar .btn:hover .hover-area span {
  margin-left: auto;
  margin-right: 4px;
}

.drive-view {
  height: 100%;
  width: 100%;
  overflow: hidden;
}`],
})
export class PageMainComponent implements OnInit {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public workspace: any;
    public shortcuts: any = [];

    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public ref: ViewContainerRef    ) {
        this.dizest = new Dizest(service);

        this.dizest.editorType.bind({
            cls: ImageEditor,
            trigger: (ext, node) => {
                if (['png', 'jpg', 'jpeg', 'gif', 'ico', 'icon'].includes(ext)) {
                    return true;
                }
            }
        });

        this.dizest.editorType.bind({
            cls: VideoEditor,
            trigger: (ext, node) => {
                if (['mp4', 'avi'].includes(ext)) {
                    return true;
                }
            }
        });

        this.dizest.editorType.bind({
            cls: WorkflowEditor,
            trigger: (ext, node) => {
                if (['dwp'].includes(ext)) {
                    return true;
                }
            }
        });

        this.dizest.editorType.bind({
            cls: DefaultEditor,
            trigger: (ext, node) => {
                return true;
            }
        });

        this.shortcuts.push({
            key: ["cmd + s", "ctrl + s"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.update)
                    await this.workspace.selected.update();
            }
        }, {
            key: ["alt + w"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.closeWindow)
                    await this.workspace.selected.closeWindow();
                else
                    await this.workspace.selected.close();
            }
        }, {
            key: ["shift + enter"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.run)
                    await this.workspace.selected.run();
            }
        }, {
            key: ["cmd + r", "ctrl + r"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.run)
                    await this.workspace.selected.run();
            }
        }, {
            key: ["alt + a"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.alta)
                    await this.workspace.selected.alta();
            }
        }, {
            key: ["cmd + 1", "ctrl + 1"],
            preventDefault: true,
            command: async () => {
                let editor = this.workspace.editors[0];
                if (editor) await editor.open();
            }
        }, {
            key: ["cmd + 2", "ctrl + 2"],
            preventDefault: true,
            command: async () => {
                let editor = this.workspace.editors[1];
                if (editor) await editor.open();
            }
        }, {
            key: ["cmd + 3", "ctrl + 3"],
            preventDefault: true,
            command: async () => {
                let editor = this.workspace.editors[2];
                if (editor) await editor.open();
            }
        }, {
            key: ["cmd + 4", "ctrl + 4"],
            preventDefault: true,
            command: async () => {
                let editor = this.workspace.editors[3];
                if (editor) await editor.open();
            }
        }, {
            key: ["cmd + 5", "ctrl + 5"],
            preventDefault: true,
            command: async () => {
                let editor = this.workspace.editors[4];
                if (editor) await editor.open();
            }
        }, {
            key: ["esc"],
            preventDefault: true,
            command: async () => {
                if (!this.workspace.selected) return;
                if (this.workspace.selected.esc)
                    await this.workspace.selected.esc();
            }
        });

        for (let i = 0; i < this.shortcuts.length; i++)
            this.shortcuts[i].allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];
    }

    public async ngOnInit() {
        await this.service.init();
        if (WizRoute.segment[0])
            await this.service.auth.allow("/api/dizest/auth?redirect=/workflow/" + WizRoute.segment[0]);
        else
            await this.service.auth.allow("/api/dizest/auth");
        await this.dizest.kernels();
        await this.dizest.loadConfig();
        await this.service.render();
        let socket: any = wiz.socket();
        this.workspace = new Workspace(this, this.editorElement, socket);

        if (WizRoute.segment[0] && WizRoute.segment[0][0] != '#') {
            let ext = WizRoute.segment[0].split(".")[WizRoute.segment[0].split(".").length - 1];
            await this.driveConfig.open({ id: decodeURIComponent(WizRoute.segment[0]) }, ext);
        } else {
            await this.service.href("/workflow");
        }
    }

    public workspaceSortableOption: any = {
        animation: 0,
        handle: '.view-tab'
    };

    public editorLoading: boolean = false;

    public driveConfig: any = {
        open: async (node, ext) => {
            this.editorLoading = true;
            await this.service.render();
            let opts: any = this.dizest.editorType.select(ext, node);
            opts.extension = ext;
            await this.workspace.open(node.id, opts);
            await this.dizest.kernels();
            this.editorLoading = false;
            await this.service.render();
            return true;
        }
    };

    public async open(menu_id) {
        let mapper: any = {
            setting: { cls: SettingEditor },
            health: { cls: HealthEditor },
            terminal: { cls: TerminalEditor },
        };
        if (!mapper[menu_id]) return;

        let target = menu_id;
        if (menu_id == 'terminal') {
            target = 'term-' + this.service.random(6);
        }

        await this.workspace.open("#" + target, mapper[menu_id]);
        await this.service.render();
    }

    public isHideDrive: boolean = false;

    public async toggleDrive() {
        this.isHideDrive = !this.isHideDrive;
        await await this.service.render();
    }
}

export default PageMainComponent;